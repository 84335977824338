import logo from "../assets/sectionf-logo.svg";
import sectionf from "../assets/sectionf.svg";
import registered from "../assets/registered.svg";
import waitingText from "../assets/waitingForYou.svg";

const RegisteredPage = () => {
  return (
    <>
        <img className="logo-registered" src={logo} alt="logo" />
        <img className="sectionf" src={sectionf} alt="sectionf" />
        <img className="registered" src={registered} alt="registered" />
        <img className="waiting-text" src={waitingText} alt="waiting-text" />
    </>
  )
}

export default RegisteredPage