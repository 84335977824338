// Import the functions you need from the SDKs you need
import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAgYtrmICNWHddG7FqJDlfUNPWSgUPy1jc",
    authDomain: "sectionf-opening.firebaseapp.com",
    projectId: "sectionf-opening",
    storageBucket: "sectionf-opening.appspot.com",
    messagingSenderId: "584434261087",
    appId: "1:584434261087:web:39f3f7ca1798b02ab14fea"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db };
export default firebase;