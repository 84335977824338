import logo from "./assets/sectionf-logo.svg";
// import taala from "./assets/taala.svg";
// import maana from "./assets/maana.svg";
// import sectionha from "./assets/sactionha.svg";
// import exclamationMarks from "./assets/exclamation-marks.svg";
// import footerImage from "./assets/man-holding-burger.svg";

import { db } from "./utils/firebase";
import firebase from "firebase";

import "./App.css";

import { useState } from "react";
import useLocalStorage from "./hooks/useLocalStorage";
import RegisteredPage from "./components/RegisteredPage";

function App() {
  const [isRegistered, setIsRegistered] = useLocalStorage("registered", false);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("")

  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState("")

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  const handleClick = () => {
    setNameError("")
    setNumberError("")

    if(name.length <= 2) {
      setNameError("Please enter your name!")
      return
    }
    if(number.length <= 10) {
      setNumberError("Please enter your phone number!")
      return
    }
    
    // TODO: save record
    db.collection("users").add({
      name,
      number,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      // TODO: register local cache
      setIsRegistered(true)
    })
  };

  return (
    <div className="container">
      {!isRegistered ? (
        <>
          <img className="logo" src={logo} alt="logo" />
          <div className="input-grp">
            <label className="label">Name</label>
            <input className="input" value={name} onChange={handleNameChange} />
            { nameError !== "" && <p className="error">{nameError}</p> }
          </div>
          <div className="input-grp">
            <label className="label">Phone No.</label>
            <input
              className="input"
              type="number"
              value={number}
              onChange={handleNumberChange}
            />
            { numberError !== "" && <p className="error">{numberError}</p> }
          </div>
          <button className="button" onClick={handleClick} disabled={name === "" || number === ""}>
            Done
          </button>
          {/* <div className="footer">
            <div className="footer-text">
              <img src={taala} alt="taala-text" />
              <img src={maana} alt="maana-text" />
              <div className="sectionha-container">
                <img src={exclamationMarks} alt="exclamation-marks" />
                <img src={sectionha} alt="sectionha" />
              </div>
            </div>
            <img className="footer-image" src={footerImage} alt="footer" />
          </div> */}
        </>
      ) : (
        <RegisteredPage />
      )}
    </div>
  );
}

export default App;
